import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if ('serviceWorker' in navigator && 'PushManager' in window) {
  navigator.serviceWorker.register('/service-worker.js')
  .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
      console.log(registration);
      // Check for existing subscription
      return registration.pushManager.getSubscription();
  })
  .then((subscription) => {
      if (subscription) {
          console.log('User is  subscribed:', subscription);
          // Handle existing subscription
      } else {

      }
  })
  .catch((error) => {
      console.error('Service Worker registration or push subscription failed:', error);
  });
} else {
  console.error('Service Worker or Push Manager not supported in this browser.');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
